/**
 * Fonction de callback lors d'un requête vers une URL extérieure
 * @param {string} url - L'URL de la requête
 * @param {string} resourceType - Le type de ressource
 * @param {string} urlmap - L'URL de base pour la carte
 * @returns {(object| null)} Le token d'authentifcation lorsqu'il est nécessaire
 */
export function transformRequest(url, resourceType, urlmap) {
  if ((resourceType === 'Source' || resourceType === 'Tile') && url.startsWith(urlmap)) {
    return {
      url,
      headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
    }
  }
  return null
}

export const getSignalLayerId = type => `signal_${type.toLowerCase().replace(/ |\./g, '_')}`
